const pad = (a) => {
  return a <= 9 ? `0${a}` : a;
};

// date(Dec 31, 2019 23:01:59) => str(2019-12-31)
export function formatSys2OdooDate(a) {
  try {
    const b = a.getDate();
    const c = a.getMonth() + 1;
    const d = a.getFullYear();
    // Month from 0 to 11
    return `${d}-${pad(c)}-${pad(b)}`;
  } catch (e) {
    return null;
  }
}

// str(2019-12-31) => date(Dec 31, 2019 23:01:59)
function formatOdooDate2Sys(a) {
  if (a !== 0 && a !== null && a !== "" && !1 !== a) {
    try {
      const b = a.substring(0, 10).split("-");
      return new Date(
        parseInt(b[0], 10),
        parseInt(b[1], 10) - 1,
        parseInt(b[2], 10)
      );
    } catch (e) {
      return "";
    }
  }
  return "";
}

export default function nextMonth(m) {
  const d = formatOdooDate2Sys(`${m}-01`);
  d.setMonth(d.getMonth() + 1);
  const e = formatSys2OdooDate(d);
  return e.substring(0, 7);
}
