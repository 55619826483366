const chr = (n) => String.fromCharCode(n);
const ord = (c) => c.charCodeAt(0);
export function base10(itr1) {
  let itr = itr1;
  let out = 0;
  if (!itr) {
    return false;
  }
  itr = itr.toUpperCase();
  const cksum1 = itr.substring(itr.length - 2, itr.length - 1);
  const cksum3 = itr.substring(itr.length - 1, itr.length);

  if (ord(cksum1) < 65 || ord(cksum1) > 90) {
    return false;
  }
  if (ord(cksum3) < 65 || ord(cksum3) > 90) {
    return false;
  }
  itr = itr.substring(0, itr.length - 2);
  let sumrem = 0;

  for (let i = 0; i < itr.length; i += 1) {
    const char = ord(itr.substring(itr.length - i - 1, itr.length - i)) - 65;
    if (char < 0 || char > 25) {
      return false;
    }
    out += 26 ** i * char;
    sumrem += char;
  }

  // let div = Math.floor(sumrem / 26);
  let rem = sumrem % 26;
  const cksum = chr(rem + 65);
  // div = Math.floor((sumrem * 2) / 26);
  rem = (sumrem * 2) % 26;
  const cksum2 = chr(rem + 65);
  if (cksum === cksum1 && cksum2 === cksum3) {
    return out;
  }
  return false;
}

export function base26(n) {
  if (n <= 0) {
    return "Invalid Registration Number";
  }

  let out = "";
  let sumrem = 0;
  let itr = n;
  let div;
  let rem;
  while (itr > 0) {
    div = Math.floor(itr / 26);
    rem = itr % 26;
    out = String.fromCharCode(65 + rem) + out;
    itr = div;
    sumrem += rem;
  }
  rem = sumrem % 26;
  const cksum = String.fromCharCode(rem + 65);
  rem = (sumrem * 2) % 26;
  const cksum2 = String.fromCharCode(rem + 65);
  out += cksum + cksum2;

  return out;
}
