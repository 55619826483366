/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
// import Footer from "../SiteFooter";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div>
        <main>{children}</main>
      </div>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
